import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL; //'http://localhost:3000/api/v1';

const apiClient = axios.create({
    baseURL: API_URL,
    withCredentials: true, // Include cookies in the requests
});

//apiClient.interceptors.request.use(async config => {
//  let token = getCookie('token'); // Implement getCookie function to read cookies
//
//  if (isTokenExpired(token)) {
//   // token = await refreshAccessToken();
//  }
//
//  config.headers['Authorization'] = `Bearer ${token}`;
//  return config;
//}, error => {
//  return Promise.reject(error);
//});

apiClient.interceptors.response.use(
    (response) => response, // Simply return the response for successful requests
    async (error) => {
        const originalRequest = error.config;

        // Check if the response is 401 and not a retry request to avoid infinite loops
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // Mark this request as a retry
            try {
                // Attempt to renew the token by calling the refresh token endpoint
                await apiClient.get(`/user/refreshToken`);
                // If successful, retry the original request
                return apiClient(originalRequest);
            } catch (refreshError) {
                // If token renewal fails, handle accordingly (e.g., redirect to login)
                return Promise.reject(refreshError);
            }
        }
        // For other errors, just return the error
        return Promise.reject(error);
    }
);

const refreshAccessToken = async () => {
    try {
        const response = await apiClient.get('/user/refreshToken');
        return response.data.token;
    } catch (error) {
        throw new Error('Error refreshing token');
    }
};

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

const isTokenExpired = (token) => {
    if (!token) return true;
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    return decodedToken.exp * 1000 < Date.now();
};

export const fetchDataGet = async (endpoint) => {
    try {
        const response = await apiClient.get(`/${endpoint}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data');
    }
};

export const fetchDataGetWithBody = async (endpoint, data) => {
    try {
        const response = await apiClient.get(`/${endpoint}`, {
            params: data,
        });
        return response;
    } catch (error) {
        return error.response;
    }
};

export const fetchDataPost = async (endpoint, formData) => {
    try {
        const response = await apiClient.post(`/${endpoint}`, formData);
        return response;
    } catch (error) {
        console.error('Error in fetchDataPost:', error);
        throw error;
    }
};

export const fetchDataPut = async (endpoint, data) => {
    try {
        const response = await apiClient.put(`/${endpoint}`, data);
        return response;
    } catch (error) {
        console.error('Error in fetchDataPut:', error);
        throw error;
    }
};

export const fetchDataDelete = async (endpoint) => {
    try {
        const response = await apiClient.delete(`/${endpoint}`);
        return response;
    } catch (error) {
        console.error('Error in fetchDataDelete:', error);
        throw error;
    }
};

export const fetchDataPostWithProgress = (
    endpoint,
    formData,
    setUploadProgress
) => {
    const token = getCookie('token'); // Ensure you have a function to get the token

    return new Promise((resolve, reject) => {
        apiClient
            .post(`/${endpoint}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`, // Include the token here
                },
                onUploadProgress: (event) => {
                    console.log(`Progress calling`); // Check console logs
                    const progress = Math.round(
                        (event.loaded * 100) / event.total
                    );
                    console.log(`Upload Progress: ${progress}%`); // Check console logs
                    setUploadProgress(progress);
                },
            })
            .then((response) => {
                console.log('Upload Success:', response); // Check console logs
                resolve(response);
            })
            .catch((error) => {
                console.error('Upload Error:', error); // Check console logs
                reject(error);
            });
    });
};
